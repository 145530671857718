<template>
  <div>
    <div v-if="isLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else :class="studentList.length > 0 ? 'min-h-screen' : 'heightContent'">
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">All Student</h1>
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="grid grid-cols-6 justify-start gap-2 mb-4">
          <div class="">
            <TextField type="date" label="Start Date" v-model="startDate" borderEnabled placeholder="Select Date" />
          </div>
          <div class="">
            <TextField type="date" label="End Date" v-model="endDate" borderEnabled placeholder="Select Date" />
          </div>
          <div class="grid grid-cols-2 h-full mt-5 ml-2">
            <Button buttonText="Filter" type="secondary" @action="filterOrSearch" additionalClass="bg-yellow h-11 mr-2" />
            <Button v-show="isDefaultFilter" buttonText="Clear" type="tertiary" @action="clearFilter" additionalClass=" h-11" />
          </div>
        </div>
        <div class="flex items-center mb-7">
          <div class="w-1/4">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Search Student</label>
            <div class="relative">
              <TextField type="text" :enterKeyAction="filterOrSearch" v-model="keyword" withIcon borderEnabled placeholder="Name, email, or phone number" />
              <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                <Search color="#58595B" />
              </div>
            </div>
          </div>
          <div class="flex items-center h-full ml-3 mt-4">
            <Button buttonText="Search" @action="filterOrSearch" additionalClass="bg-yellow h-11 mr-2" />
            <Button v-show="keyword" buttonText="Clear" type="tertiary" @action="clearSearch" additionalClass=" h-11" />
          </div>
        </div>
        <vue-good-table
          v-if="studentList.length > 0"
          mode="remote"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: size,
            position: 'bottom',
            perPageDropdown: [5, 10],
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All',
            setCurrentPage: pageTable
          }"
          :totalRows="totalRows"
          :rows="studentList"
          :columns="tableHeader"
          :sort-options="{
            enabled: false
          }"
          styleClass="vgt-table bordered my-table"
          @on-page-change="onPageChange"
          @on-per-page-change="onSizeChange"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'name'" class="cursor-pointer text-yellow" @click="toDetail(props.row.id)">
              {{ props.row[props.column.field] }}
            </div>
            <div v-else :class="props.column.field === 'classes' ? 'text-center' : ''">
              {{ props.row[props.column.field] }}
            </div>
          </template>
        </vue-good-table>
        <div v-else class="border rounded-lg h-64 flex justify-center items-center">
          <div class="bg-pink">
            <p class="text-center text-neutral-400">No data available</p>
            <p class="text-center text-yellow font-bold cursor-pointer" @click="clearAll">Clear All</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
export default {
  name: 'Student',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search')
  },
  data: function () {
    return {
      isLoading: false,
      sizeChange: false,
      startDate: null,
      endDate: null,
      keyword: null,
      studentList: [],
      size: 10,
      currentPage: 0,
      totalRows: 0
    }
  },
  computed: {
    customerId() {
      return localStorage.getItem('client')
    },
    tableHeader() {
      return [
        { field: 'name', label: 'Name' },
        { field: 'email', label: 'Email' },
        { field: 'phoneNumber', label: 'Phone Number' },
        { field: 'classes', label: 'Classes' },
        { field: 'createdDate', label: 'Created Date' },
        { field: 'lastUpdate', label: 'Last Update' }
      ]
    },
    pageTable() {
      return this.currentPage + 1
    },
    isDefaultFilter() {
      return this.startDate || this.endDate ? true : false
    }
  },
  mounted() {
    this.isLoading = true
    this.getData()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('student', ['GET_CRM_STUDENT']),
    filterOrSearch() {
      this.currentPage = 0
      this.getData()
    },
    clearFilter() {
      this.startDate = this.endDate = null
      this.currentPage = 0
      this.getData()
    },
    clearSearch() {
      this.keyword = null
      this.currentPage = 0
      this.getData()
    },
    clearAll() {
      this.startDate = this.endDate = this.keyword = null
      this.currentPage = 0
      this.getData()
    },
    toDetail(id) {
      this.$router.push(`student/${id}`)
    },
    onPageChange(params) {
      this.currentPage = params.currentPage - 1
      this.getData()
    },
    onSizeChange(params) {
      if (!this.sizeChange) {
        this.sizeChange = true
        this.size = params.currentPerPage
        this.currentPage = 0
        this.getData()
      }
    },
    getData() {
      this.showLoading()
      this.GET_CRM_STUDENT({
        params: {
          customerId: this.customerId,
          page: this.currentPage,
          size: this.size,
          sortBy: 'updatedDate',
          direction: 'DESC',
          lastUpdatedStart: this.startDate ? `${moment(this.startDate).format('YYYY-MM-DD')}T00:00:00.000` : '',
          lastUpdatedEnd: this.endDate ? `${moment(this.endDate).format('YYYY-MM-DD')}T23:59:59.999` : '',
          q: this.keyword ? this.keyword : ''
        }
      })
        .then((res) => {
          this.totalRows = res.data.data.totalRows
          this.size = res.data.data.size
          this.currentPage = res.data.data.currentPage
          this.studentList = []
          res.data.data.data.forEach((student) => {
            this.studentList.push({
              id: student.id,
              name: student.name,
              email: student.email,
              phoneNumber: student.phoneNumber,
              classes: student.numberOfClasses,
              createdDate: moment(student.createdDate).format('DD MMM YYYY HH:mm'),
              lastUpdate: moment(student.updatedDate).format('DD MMM YYYY HH:mm')
            })
          })
          setTimeout(() => {
            this.isLoading = this.sizeChange = false
            this.hideLoading()
          }, 1000)
        })
        .catch(() => {
          this.isLoading = false
          this.hideLoading()
        })
    }
  }
}
</script>
<style scoped lang="scss">
.heightContent {
  height: calc(100vh - 148px);
}
.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
